import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

import style from './index.module.scss';
import { graphql } from 'gatsby';

import { ElementSwitch } from '../utils/cockpit-element-switch';

const IndexPage = ({ data }) => {
  const pageData = data.cockpitHome;

  return (
    <Layout>
      <SEO title="Home" keywords={[`k9 kommando`, `gaming`, `clan`]} />
      <h1 className={style.homepage}>{pageData.title.value}</h1>
      {Boolean(pageData.contentElements) && (
        <ElementSwitch elements={pageData.contentElements.value} />
      )}
      <br />
    </Layout>
  );
};

export const query = graphql`
  query {
    cockpitHome {
      title {
        value
      }
      contentElements {
        value {
          _id
          link
        }
      }
    }
  }
`;

export default IndexPage;
